<template>
  <main class="page-points page-pick-point p-0 d-flex flex-column">
    <ul class="nav nav-pills nav-fill">
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'points.show' }">Dati</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'points.edit' }">Modifica</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link active" :to="{ name: 'points.pick' }" aria-current="page">Punto</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :class="{ 'disabled': !point.hasContours }" :to="{ name: 'points.contours' }">Contorni</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'points.notes' }">Contenuti</router-link>
      </li>
    </ul>
    <div ref="page" class="flex-grow-1 container-fluid overflow-hidden pt-3 px-0 ">
      <canvas ref="can"></canvas>
    </div>
  </main>
</template>

<script>

import { visea } from '@/libs/Editor/visea';

export default {
  name: 'module-edit',
  props: {
    project: {
      type: Object,
      required: true,
    },
    module: {
      type: Object,
      required: true,
    },
    point: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      feedback: null,
      editorData: {},
    };
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.can.width = this.$refs.page.clientWidth;
      this.$refs.can.height = this.$refs.page.clientHeight;

      visea.initCanvas(this.$refs.can);
      this.viseaPointMode({ x: this.point.x, y: this.point.y }, this.viseaPointCb);
      visea.loadImg(this.backgroundImage);
      visea.callbacks.hook();
    });
  },
  computed: {
    editorStyle () {
      const style = {};

      if (!this.project?.image) {
        return style;
      }

      style['background-image'] = 'url(' + this.project?.image?.url + ')';

      return style;
    },
    backgroundImage () {
      return this.project?.image?.url;
    },
  },
  methods: {
    viseaPointCb (obj) {
      this.saveEditorData(obj.x, obj.y);
    },
    viseaPointMode (point, cb) {
      visea.initMode(visea.EDITOR_POINT);
      if (point) {
        visea.util.setViseaPoint(point);
      }
      visea.internals.callbacks.onPointClick = cb;
    },
    saveEditorData (x, y) {
      this.$api.updatePointCoords(this.point.id, parseInt(x) || 0, parseInt(y) || 0)
        .then(res => {
          this.$emit('update:point', res.data);
        })
      ;
    },
  },
};

</script>
